@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {

  .floatButton {
    z-index: 999;
    position: fixed; bottom: 30px; right: 30px;
  }

}
.nav-column {
    overflow: hidden;
}

.content-column {
    height: 80vh;
    overflow-y: scroll;
}