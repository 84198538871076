.rent {
  .rentPeriod {
    .form-group {
      width: 70px !important;
    }


  }

  .failed {
    color: red;
  }
}