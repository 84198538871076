.searchModal {
  max-width: inherit !important;

  .leftPanel {
    .card {
      min-height: 644px !important;
    }
  }

  .failed {
    color: red;
  }
}